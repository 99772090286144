/** @jsx jsx */
import { jsx, Box, MenuButton, Flex, Button } from 'theme-ui';
import { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import Sticky from 'react-stickynode';
import { Link, NavLink } from 'components/link';
import menuItems from './header.data';
import ScrollLogo from '../scrollLogo'

export default function Header() {
  const [mobileMenu, setMobileMenu] = useState(false);

  const openMobileMenu = () => {
    setMobileMenu(true);
  };

  const closeMobileMenu = () => {
    setMobileMenu(false);
  };

  return (
    <Box sx={styles.headerWrapper}>
      <Sticky enabled={true} top={0} activeClass="is-sticky" innerZ={100}>
        <Box
          as="header"
          variant="layout.header"
          className={mobileMenu ? 'is-mobile-menu' : ''}
        >
          <Box 
            sx={{
                maxWidth: [null, null, '1200px', '1480px'],
                px: '30px',
            }}
          >
            <Box sx={styles.headerInner}>
                {/* <Logo isWhite={mobileMenu} /> */}
                <ScrollLogo showBelow={10}/>
                

              <Flex
                as="nav"
                sx={styles.navbar}
                className={mobileMenu ? 'navbar active' : 'navbar'}
              >
                <Box
                  as="ul"
                  sx={styles.navList}
                  className={mobileMenu ? 'active' : ''}
                >
                  {menuItems.map(({ path, label }, i) => (
                    <li key={i}>
                      <NavLink
                        path={path}
                        label={label}
                        onClick={closeMobileMenu}
                      />
                    </li>
                  ))}
                  <li>
                    <Link to="/qanda" className="nav-item" sx={{
                      textDecoration: 'none',
                      color: '#4793D7',
                    }}>Q&A</Link>
                  </li>
                </Box>
              </Flex>

              {mobileMenu ? (
                <Button variant="text" sx={styles.closeButton}>
                  <GrClose
                    onClick={closeMobileMenu}
                    color="#61A2DD"
                    size="20px"
                  />
                </Button>
              ) : (
                <MenuButton 
                sx={{
                  display: ['block', 'block', 'block', 'block', 'none', ],
                }}
                aria-label="Toggle Menu" onClick={openMobileMenu} />
              )}
            </Box>
            </Box>
        </Box>
      </Sticky>
    </Box>
  );
}

const styles = {
  headerWrapper: {
    backgroundColor: 'transparent',
    '.is-sticky': {
      header: {
        '::before': {
          boxShadow: '0 6px 13px rgba(38, 78, 118, 0.1)',
          height: '100%',
        },
      },
    },
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: ['space-between', ],
    '@media only screen and (max-width: 1200px)': {
      '.navbar': {
        position: 'absolute',
        top: '100%',
        background: 'linear-gradient(161.38deg, #FFE8E5 1.99%, #E4F1FD 87.36%)',
        width: '100%',
        left: 0,
        p: '10px 59px',
        display: 'block',
        boxShadow: '0 6px 13px rgba(38,78,118,0.1)',
        opacity: 0,
        visibility: 'hidden',
        minHeight: 'calc(100vh - 77px)',
        transition: 'all 0.3s ease-in-out 0s',
        '&.active': {
          opacity: 1,
          visibility: 'visible',
        },
        ul: {
          display: 'block',
          'li + li': {
            marginTop: 5,
          },
          a: {
            color: '#61A2DD',
          },
        },
      },
    },
  },
  navbar: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
  },
  navList: {
    display: ['flex'],
    alignItems: 'center',
    listStyle: 'none',
    marginLeft: 'auto',
    height: '51px',
    p: 0,
    gap: [null, null, '0 6.25vw'],
    '.nav-item': {
      cursor: 'pointer',
      fontWeight: 400,
      padding: 0,
      whiteSpace: 'pre'
    },
    '.active': {
      // color: 'primary',
    },
  },
  explore: {
    display: ['block', 'block', 'block', 'block', 'none'],
    position: 'absolute',
    bottom: 40,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  closeButton: {
    height: '32px',
    padding: '4px',
    minHeight: 'auto',
    width: '32px',
    ml: '3px',
    path: {
      stroke: '#61A2DD',
    },
  },
};
