/** @jsx jsx */
import { jsx} from 'theme-ui';
import { useState, useEffect } from 'react'
import Logo from './logo'
import { keyframes } from '@emotion/core';

const Scroll = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

  return (
    <div sx={{
      transition: 'background-color 0.3s ease',
      transitionDuration: '.3s',
      animation: `${fadeIn} 0.8s linear`,
    }}>
      {show && (
        // <button onClick={handleClick} id="scrollBtn" aria-label="to top">
          <Logo onClick={handleClick} id="scrollBtn" aria-label="to top"/>
        // </button>
      )}
    </div>
  )
}
export default Scroll